import React from "react";
import Image from "./basico/image";
import { Container, Row, Col } from 'react-bootstrap';

export default function FrameFacebook({marca}) {
  return (
    <Container>
      <h4 className="p-4 bg-light">
        Facebook:
      </h4>

      {marca === "nissan" ? (
        <iframe
          title="Facebook Page"
          src={
            "https://www.facebook.com"
              + "/plugins/page.php"
              + `?href=https://www.facebook.com/profile.php?id=61551072302727`
              + "&tabs=timeline"
              + "&width=300&height=700"
              + "&small_header=false"
              + "&adapt_container_width=true"
              + "&hide_cover=false"
              + "&show_facepile=false"
              + "&appId=545632622595257"
          }
          width="100%" height="700"
          scrolling="no"
          frameBorder={0}
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      ) : (
        <iframe
          title="Facebook Page"
          src={
            "https://www.facebook.com"
              + "/plugins/page.php"
              + `?href=https%3A%2F%2Fwww.facebook.com%2Fagenciarivero%2F`
              + "&tabs=timeline"
              + "&width=300&height=700"
              + "&small_header=false"
              + "&adapt_container_width=true"
              + "&hide_cover=false"
              + "&show_facepile=false"
              + "&appId=545632622595257"
          }
          width="100%" height="700"
          scrolling="no"
          frameBorder={0}
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      )}
      <h4 className="p-4 bg-light">
        Siguenos:
      </h4>
      <Row>
        <Col>
          <a href={"https://twitter.com/agenciarivero?lang=es"} rel="noreferrer" tag="_blank">
            <Image class="socialIcon" fileName="icon-twitter.png" alt="twitter"/>
          </a>
        </Col>
        <Col>
          <a href={marca === "nissan" ? "https://www.facebook.com/profile.php?id=61551072302727" : "https://www.facebook.com/agenciarivero/"} rel="noreferrer" tag="_blank">
            <Image class="socialIcon" fileName="icon-facebook.png" alt="facebook"/>
          </a>
        </Col>
        {marca === "nissan" ? null : (
          <Col>
            <a href="https://www.youtube.com/user/gruporivero" rel="noreferrer" tag="_blank">
              <Image class="socialIcon" fileName="icon-youtube.png" alt="youtube"/>
            </a>
          </Col>
        )}
        <Col>
          <a href={marca === "nissan" ? "https://www.instagram.com/nissanrivero/" : "https://www.instagram.com/gruporivero/?hl=es-la"} rel="noreferrer" tag="_blank">
            <Image class="socialIcon" fileName="icon-instagram.png" alt="instagram"/>
          </a>
        </Col>
      </Row>
  </Container>
  )
}

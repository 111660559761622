import React, { useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";
import "moment/locale/es-mx";
import { Script } from "gatsby";

import SEO from "../../components/seo";
import api from "../../actions/riveroapi";
import Layout from "../../components/layouts/layout";
import { BreadCrumbBlogs } from "../../components/widgets/breadCrumb";
import FrameFacebook from "../../components/widgets/FrameFacebook";

export default function DetalllesBlogs(props){

    const [ splitMarca, setSplitMarca ] = useState();

    const parameters = props.location.search;

    const path = props.location.pathname.split("/");
    const slug = path.slice(path.length - 4).join("/");
    console.log("URL", path)
    moment.locale('es');

    const [blog, setBlog] = useState([]);

    useEffect(() => {
        setSplitMarca(path[1]);
        api.getBlog(path[3]).then((resp) => {
        setBlog(resp.data);
        console.log(resp.data)
        }).catch((error) => console.log("Error: ", error));
    }, []);

    return(
        <Layout parameters={parameters}>
            <SEO
                title="Chevrolet Rivero Monterrey - Entradas del blog"
                description={
                "Chevrolet Rivero - Agencia Chevrolet Rivero Monterrey  Aveo, Beat, " +
                "Spark, Onix, Cavalier, Trax - venta autos nuevos y " +
                "seminuevos - Chevrolet Guadalupe, Santa Catarina, LindaVista"
                }
                keywords="Agencia Chevrolet Monterrey"
                path="/blog"
            />
            <Container className="p-4 bg-white">
                <Row>
                    <Col lg={8} sm={12} md={8}>
                        {<BreadCrumbBlogs title={blog?.title} marca={'chevrolet'}/>}
                            <Row className="pt-2">
                                <Col lg={12} sm={12} md={12} style={{ cursor: 'default' }}>
                                    <h3 className="style-titulo-blog">
                                        {blog?.title}{""}
                                    </h3>
                                    <img
                                        src={blog ? blog.image : ""}
                                        alt={blog ? blog.title : "Chevrolet Rivero Blog0"}
                                        width="100%"
                                    />
                                </Col>
                                <Col lg={12} sm={12} md={12}>
                                    <span className="style-fecha-blog-paginacion p-2">
                                        <div style={{display: 'inline-block'}}> 
                                            <FaCalendarAlt size={15} />
                                        </div>
                                        <p className="text-fecha">
                                            {moment(blog?.date).format('LL')}{""}
                                        </p>
                                    </span>
                                    {<div className="p-2"
                                        dangerouslySetInnerHTML={{ __html: blog?.content }}
                                        style={{ textAlign: "justify" }}
                                    />}
                                </Col>
                            </Row>
                    </Col>
                    <Col lg={4} sm={12} md={3}>
                        <FrameFacebook marca={'chevrolet'}/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}